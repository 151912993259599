import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/kszol/projects/noeadogatsby/src/components/layout/default-page-layout.tsx";
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import HogyanRendelkezhetNav from '../components/hogyan-rendelkezhet-nav.mdx';
export const query = graphql`
  query {
    file(relativePath: { eq: "1szazalek2018_InfoTandem_net.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: TRACED_SVG
          layout: FULL_WIDTH
          breakpoints: [324, 448, 576, 800]
        )
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Állataink élete az 1 %-on múlik! RAJTAD MÚLIK!`}</h1>
    <h2>{`Kedves Állatbarát!`}</h2>
    <p>{`Alapítványunk a személyi jövedelemadó 1%-ból működik, ez a legnagyobb bevételi forrásunk, állatmentő munkánkat szinte teljes egészében ebből fedezzük.`}</p>
    <div className="not-prose font-bold">
  <p className="text-lg font-bold">2024-től a felajánlásra jogosultak listájában is már NOÉ Állatotthon Alapítvány néven találsz meg minket!<br />
    Adószám: 18169696-1-42</p>
    </div>
    <p>{`Örömmel tapasztaljuk, hogy állatotthonunk munkájának elismeréseképpen az adózók egy része úgy gondolja, hogy az adója 1 %-ával a NOÉ Állatotthon Alapítvány tevékenységét támogatja. Nagyon köszönjük, és reméljük, hogy idén is számíthatunk támogatásukra, hiszen jelenleg több mint 1.200 állatról gondoskodunk!`}</p>
    <p>{`Időközben nemcsak Budapest, de Magyarország legnagyobb állatotthonává nőttük ki magunkat. 2017. januárjától már Vas megyében, Szergényben is működtetünk egy kutyamenhelyet, a hozzá szervesen kapcsolódó celldömölki karantén részleggel. Itt első sorban a környék rászoruló kutyáit, a környező gyepmesteri telepek halálsorairól mentjük az állatokat. Nálunk minden állat kap egy esélyt, sőt ha szükséges második esélyt is, és ezt támogatóinknak köszönhetjük, akik adójuk 1 %-ával, anyagi és tárgyi adományokkal, valamint kétkezi munkájukkal segítik a budapesti állatotthon és szergényi kutyamenhely mindennapi működését, fennmaradását és fejlődését.`}</p>
    <p>{`Kérjük, támogassa a NOÉ Állatotthon Alapítányt adója 1%-ával, hogy továbbra is ilyen magas színvonalon végezhessük állatmentő munkánkat. Az adóbevallás részét képező felajánló nyomtatványra írja rá adószámunkat:`}</p>
    <div className="not-prose text-center font-bold">
  <p className="">Adószámunk:</p>
  <p className="text-xl">18169696-1-42</p>
  <p className="">
    <a className="underline underline-offset-2 text-[#0088cc] hover:text-[#005580]" href="/images/rendelkezo_nyilatkozat.jpg" download="rendelkezo-nyilatkozat.jpg">
      Rendelkező nyilatkozat letöltése
    </a>
  </p>
    </div>
    <div className="flex flex-wrap my-4">
  <div className="w-full md:w-1/2 pr-0 md:pr-2 lg:pr-4">
    <a className="block border-2 border-transparent hover:border-blue-300" aria-label="Adó 1% rendelkezés infografika megnyitása" href={'/letoltes/1szazalek2018_InfoTandem_net.pdf'} target="_blank" rel="noreferrer">
      <GatsbyImage alt="" image={props.data.file.childImageSharp.gatsbyImageData} mdxType="GatsbyImage" />
    </a>
  </div>
  <div className="w-full md:w-1/2 pl-0 md:pl-2 lg:pl-4 pt-2">
    <HogyanRendelkezhetNav mdxType="HogyanRendelkezhetNav" />
  </div>
    </div>
    <p>{`Köszönjük!`}</p>
    <p>
  Legyen Ön is a NOÉ család tagja, csatlakozzon hozzánk
  <br />
  <a href="http://facebook.com/noeallatotthon" target="\_blank" rel="noreferrer">
    Facebookon
  </a>
  <br />
  <a href="https://www.instagram.com/noeallatotthon/" target="_blank" rel="noreferrer">
    Instagramon
  </a>
  <br />
  <a href="https://www.tiktok.com/@noe_allatotthon" target="_blank" rel="noreferrer">
    Tiktokon
  </a>
    </p>
Természetesen a legnagyobb öröm védenceinknek és nekünk is, ha személyesen ellátogat
hozzánk a hét bármely napján.
    <p>{`Szeretettel várjuk :)`}</p>
    <p>{`a NOÉ Állatotthon lelkes csapata`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      